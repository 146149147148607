import React from 'react'
import { graphql } from 'gatsby'
import { compose } from 'ramda'
import marked from 'marked'
import ReactHtmlParser from 'react-html-parser'

import Layout from '../../components/layout'
import withHelmet from '../../components/hoc/withHelmet'
import { contentfulSelector, repeat } from '../../utils'

import Hero from '../../components/Hero/Hero'
import IconCallouts from '../../components/IconCallouts/IconCallouts'
import ListCallouts from '../../components/ListCallouts/ListCallouts'
import Help from '../../components/Help/Help'

import styles from './direct-deposit.module.scss'

const fromMarkup = compose(
  ReactHtmlParser,
  marked
)

const ddSelector = contentfulSelector('contentfulDirectDepositPage')

const selectHeroImage = ddSelector(['hero', 'file', 'url'])
const selectTitle = ddSelector(['title'])
const selectDescription = ddSelector(repeat('description'))
const selectIconCallouts = ddSelector(['iconCallouts'])
const selectDDCalloutTitle = ddSelector(['directDepositCalloutTitle'])
const selectDDCallouts = ddSelector(['directDepositCallouts'])
const selectDDHelp = ddSelector(repeat('helpDescription'))
const selectDDGetStartedTitle = ddSelector(['getStartedTitle'])
const selectDDGetStartedBullets = ddSelector(repeat('getStartedBullets'))

export default withHelmet(ddSelector)(DirectDeposit)

function DirectDeposit({ data }) {
  return (
    <Layout>
      <Hero imageSrc={selectHeroImage(data)} title={selectTitle(data)} />
      <div className={styles.description}>
        <div className={styles.descriptionInner}>
          {fromMarkup(selectDescription(data))}
        </div>
      </div>
      <IconCallouts callouts={selectIconCallouts(data)} />
      <ListCallouts
        title={selectDDCalloutTitle(data)}
        callouts={selectDDCallouts(data)}
      />
      <section className={styles.getStarted}>
        <h2 className={styles.getStartedTitle}>
          {selectDDGetStartedTitle(data)}
        </h2>
        <div className={styles.getStartedBullets}>
          {fromMarkup(selectDDGetStartedBullets(data))}
        </div>
      </section>
      <Help description={fromMarkup(selectDDHelp(data))} />
    </Layout>
  )
}

export const query = graphql`
  query DirectDepositQuery {
    contentfulDirectDepositPage {
      fields {
        slug
        jsonLd
      }
      seoFields {
        title
        description {
          description
        }
        pageTitle
        ogImage {
          file {
            url
          }
        }
      }
      title
      hero {
        file {
          url
        }
      }
      description {
        description
      }
      iconCallouts {
        title
        icon
        description {
          description
        }
      }
      directDepositCalloutTitle
      directDepositCallouts
      getStartedTitle
      getStartedBullets {
        getStartedBullets
      }
      helpDescription {
        helpDescription
      }
    }
  }
`
